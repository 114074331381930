import Cookies from 'universal-cookie';

export let filterByState = (data = [], state = '') => {
    if (data.length > 0 && state !== '') {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            //console.log(brokerState.trim() == state.trim())
            return brokerState.trim() == state.trim();
        })
        return data;
    }
    return [];
}

export let fillByInterState = (data = [], state = '', showRecord = false) => {
    if (data.length > 0 && state !== '') {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            showRecord = brokerState.trim() == state.trim();

            if (!showRecord)
                showRecord = node.interstate;

            return showRecord;

        })
        return data;
    }
    return [];
}

export let fillBrokerTiles = (data = [], postcodeParts = '', postcode = '', showRecord = false) => {
    if (data.length > 0) {
        data = data.filter(({ node }) => {
            let brokerState = node.stateCode;
            let broker_postcode = node.broker_postcode.split(",");
            showRecord = broker_postcode.indexOf(postcode) != -1;

            if (!showRecord) {
                let stateCode = '';
                if (postcodeParts.length == 3) {
                    stateCode = postcodeParts[2];
                    showRecord = brokerState.trim() == stateCode.trim();
                }
            }
            // if( !showRecord )
            //     showRecord = node.interstate;

            console.log(showRecord);
            return showRecord;
        });
        return data;
    }
    return [];
}

export let filterByInterState = (data = []) => {
    if (data.length > 0) {
        data = data.filter(({ node }) => {
            return node.interstate;
        })
        return data;
    }
    return [];
}


export const log = (logVar = '', separator = '-------') => {
    return false;
    console.log(separator)
    console.log(logVar)
    console.log(separator)
}

export const cookieWrite = (variable, value = '') => {
    let cookies = new Cookies();
    cookies.set(variable, value, { path: '/' });
    return cookies.get(variable)
}

export const cookieRead = (variable) => {
    let cookies = new Cookies();
    return cookies.get(variable)
}

export const oWindow = (() => {
    return {
        read: (readVar) => {
            if (typeof window != 'undefined') {
                return window.readVar;
            }
        },
        write: (writeVar = '', value = '') => {
            if (typeof window != 'undefined') {
                if (!!writeVar && !!value) {
                    window[writeVar] = value;
                    return !!window.writeVar;
                }
            }
        }
    }
})()

export const removeCookie = (removeVar = '') => {
    if (!!removeVar) {
        let cookies = new Cookies()
        cookies.remove(removeVar, { path: '/' })
    }
}

export const formatResponseTime = (responseTime) => {
    if (!responseTime) {
        return '1 min'; // default time according to new design
    } else {
        let parts = responseTime.split(" ");
        if (parts.length > 1) {
            if (parts[0] == '0') {
                return '1 min';
            } else {
                return parts[0] + ' ' + String(parts[1]);
            }
        } else {
            return '1 min';
        }
    }
}

// utils/animateNumber.js
// utils/animateNumber.js
export const animateNumber = (start, end, duration, callback, format = null) => {
    const range = end - start;
    const increment = range / (duration / 16); // Approx. 60 FPS
    let current = start;

    const updateCounter = () => {
        current += increment;
        if ((increment > 0 && current >= end) || (increment < 0 && current <= end)) {
            current = end; // Stop at the target value
            clearInterval(timer);
        }
        const formattedValue = format
            ? format(Math.round(current)) // Apply formatting if provided
            : Math.round(current);
        callback(formattedValue);
    };

    const timer = setInterval(updateCounter, 16); // Runs ~60 times per second
};

/**
 * Initialize animations when elements with the "animate" class appear in the viewport.
 */
export function initAnimations() {
    const animatedElements = document.querySelectorAll('.animate');

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add('animate'); // Trigger animation
                observer.unobserve(entry.target); // Stop observing after animation
            }
        });
    });

    animatedElements.forEach((el) => observer.observe(el));
}

/**
 * Utility function to throttle events like scroll for performance.
 */
export function throttle(callback, delay) {
    let lastTime = 0;
    return function (...args) {
        const now = Date.now();
        if (now - lastTime >= delay) {
            callback(...args);
            lastTime = now;
        }
    };
}


const isLocalhost = (typeof window !== "undefined") ? (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") : true;

let getReCaptchaKey="6LfkKwoTAAAAAFM0jzoiM17A61r7mO3d67rZnJu3"
let getGoogleAPIKey="AIzaSyDhBtxHe8EwWQlmESHI-sAwMuLqZHGvK-E&v=3.exp";
if (!isLocalhost) {
    getReCaptchaKey = "6LcMydYqAAAAAIIi9mkythFUWOkFiauBIbFSN6cs";
    getGoogleAPIKey = "6LcMydYqAAAAAPHrX8M4erSWMS6BDTd3KQ8mzFSI";
} 

export const siteName = "HFG Marketplace";
export const host = 'https://hashfinancialgroup.com/wp-json';
export const reCaptchaKey = getReCaptchaKey;
export const googleAPIKey = getGoogleAPIKey;
//export const hfg_host = 'https://hashfinancialgroup.com';